import Cookies from 'js-cookie'
import store from '@/store'

const TokenKey = 'isLogged'
const Token = 'token'
const User = 'user'
const AdminLogin = 'adminToken'
const getDomainWithoutSubdomain = () => {
  const { hostname } = window.location // e.g., sub1.sub2.example.com
  const parts = hostname.split('.') // Split by '.'

  // If it's an IP address or localhost, just return it
  if (parts.length <= 2 || hostname === 'localhost') {
    return hostname
  }

  // Return the last two parts (e.g., example.com) and add "student" to the domain
  if (parts?.length > 3) {
    return parts.slice(-3).join('.')
  }
  return parts.slice(-2).join('.')
}

export function isLogged() {
  return Cookies.get(TokenKey) === '1'
}

export function getToken() {
  return Cookies.get(Token)
}

export function getAuth() {
  return Cookies.get(User)
}

export const isUserLoggedIn = () => isLogged() && getToken() && getAuth()

export function notHasPermissions() {
  return Cookies.get(TokenKey) === '2'
}

export function setLogged(num) {
  return Cookies.set(TokenKey, num)
}

export function removeToken() {
  document.cookie = `isLogged=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  return Cookies.remove(TokenKey)
}

export function setToken(token) {
  return Cookies.set(Token, token)
}

export function deleteToken() {
  document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  return Cookies.remove(Token)
}

export function Auth(user) {
  return Cookies.set(User, user)
}

export function removeAuth() {
  document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${getDomainWithoutSubdomain()}; path=/;Secure; SameSite=None"`
  return Cookies.remove(User)
}

export function setAdminLogin(adminToken) {
  return Cookies.set(AdminLogin, adminToken)
}

export function GetAdminLogin() {
  return Cookies.get(AdminLogin)
}

export function resetPermission() {
  store.commit('roles/SET_AUTH_USER_PERMISSIONS', [])
  store.commit('roles/SET_AUTH_USER_ROLES_PERMISSIONS', [])
}
